/* General */
.module{
  position: relative;
  &.module-padding{
    padding: 50px 20px;
    @media (min-width: $screen-size-xs) and (max-width: $screen-size-lg){
      padding: 50px;
    }
  }
  @media (min-width: $screen-size-sm){
    &.module-padding {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }
  &.module-padding-top-bottom{
    padding: 50px 0;
  }
  .cta-row{
    margin-top: 35px;
  }
  &.white{
    background: #fff;
  }
  &.gray{
    background-color: $brand-gray;
  }
  &.blue{
    background-color: $brand-blue;
    color: #fff;
  }
  &.light-blue{
    background-color: $brand-light-blue;
    color: #fff;
  }
  &.orange{
    background-color: $brand-primary;
    color: #fff;
    a{
      color: #000;
      &:hover{
        color: #fff;
      }
    }
    .btn.btn-primary{
      border-color: #fff;
      color: #fff;
      &:hover{
        background-color: #fff;
        color: $brand-primary;
      }
    }
  }
}
/* Carousel Styles */
.owl-carousel {
  touch-action: manipulation;
  opacity: 0;
  -webkit-transition: opacity .1s;
  -moz-transition: opacity .1s;
  -ms-transition: opacity .1s;
  -o-transition: opacity .1s;
  transition: opacity .1s;
  &.owl-loaded{
    opacity: 1;
  }
  img{
    width: 100%;
  }
  .owl-item{
    overflow: hidden;
  }
  .slide{
    width: 100%;
    overflow: hidden;
  }
  .owl-dots {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    .owl-dot {
      display: inline-block;
      width: 40px;
      height: 5px;
      margin: 0 3px;
      background: #fff;
      border-radius: 0;
      opacity: 1;
      cursor: pointer;
      -webkit-transition: background-color .5s;
      -moz-transition: background-color .5s;
      -ms-transition: background-color .5s;
      -o-transition: background-color .5s;
      transition: background-color .5s;
      &:hover,
      &.active {
        background: $link-color;
      }
    }
  }
}

/* Slider */
.module.slider{
  position: relative;
  overflow: hidden;
  &.inline{
    opacity: 1;
  }
  .slide{
    margin: 0;
    height: 100vh;
    img,
    video{
      position: absolute;
      left: 0;
      top: 0;
      width: auto;
      height: auto;
    }
    img{
      width: auto;
      min-height: 100%;
      min-width: 100%;
      max-width: none;
      z-index: 2;
      -webkit-transition: opacity .4s ease-in;
      -moz-transition: opacity .4s ease-in;
      -ms-transition: opacity .4s ease-in;
      -o-transition: opacity .4s ease-in;
      transition: opacity .4s ease-in;
      &.mobile-img{
        display: none;
        @media (max-width: $screen-size-sm) and (orientation: portrait){
          display: inline-block;
        }
      }
      &.desktop-img{
        @media (max-width: $screen-size-sm) and (orientation: portrait){
          display: none;
        }
      }
    }
    video{
      width: 100%;
      min-width: 0;
      min-height: 0;
      height: 100%;
      z-index: 1;
      @media (min-width: $screen-size-sm){
        z-index: 2;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
      }
    }
    .slide--play-overlay{
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;
      -webkit-transition: opacity .4s ease-in;
      -moz-transition: opacity .4s ease-in;
      -ms-transition: opacity .4s ease-in;
      -o-transition: opacity .4s ease-in;
      transition: opacity .4s ease-in;
      opacity: 1;
      svg{
        width: 100px;
        color: rgba(255,255,255,0.3);
        cursor: pointer;
        -webkit-transition: color .2s;
        -moz-transition: color .2s;
        -ms-transition: color .2s;
        -o-transition: color .2s;
        transition: color .2s;
        &:hover{
          color: rgba(255,255,255,1);
        }
      }
    }
    .slide--content{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      z-index: 3;
      color: #fff;
      background-color: $overlay-background-color;
      &.slide--content--no-overlay{
        background-color: transparent;
      }
      //padding for scrollbar
      padding-left: 15px;
      padding-right: 30px;
      -webkit-transition: opacity .4s ease-in;
      -moz-transition: opacity .4s ease-in;
      -ms-transition: opacity .4s ease-in;
      -o-transition: opacity .4s ease-in;
      transition: opacity .4s ease-in;
      opacity: 1;
      .slide--content--inner{
        margin-bottom: 50px;
        @media (min-width: $screen-size-sm){
          margin-bottom: 100px;
        }
        .slide--content--headline{
          margin-bottom: 35px;
        }
        .slide--content--logo{
          margin-bottom: 30px;
          img{
            display: inline-block;
            position: static;
            width: auto;
            height: 50px;
            min-height: 0;
            min-width: 0;
            @media(min-width: $screen-size-sm){
              height: 80px;
            }
          }
        }
        &.text-left,
        &.text-right{
          min-width: 50%;
        }
      }
    }
    &.playing{
      img{
        opacity: 0;
      }
      .slide--play-overlay{
        opacity: 0;
      }
      .slide--content{
        opacity: 0;
      }
    }
    &.video-controls{
      video{
        height: 100%;
      }
    }
    .arrow{
      display: inline-block;
      position: absolute;
      cursor: pointer;
      transform: rotate(90deg);
      color: #fff;
      z-index: 999;
      right: 20px;
      bottom: 20px;
      @media(min-width: $screen-size-xs){
        right: 100px;
        bottom: -16px;
      }
    }
  }
  .slider-single{
    .slide{
      video{
        width: 100%;
      }
    }
  }
}
@media (min-width: $screen-size-sm){
  .module ~ .module.slider{
    .slide{
      height: auto;
      > img{
        position: static;
        width: 100%;
        height: auto;
        min-width: 0;
        min-height: 0;
      }
      video{
        position: relative;
        /* Fix Flickity Gap */
        margin-bottom: -9px;
      }
      &.slide--thumbnail{
        > img{
          position: relative;
        }
        video{
          position: absolute;
        }
      }
    }
  }
}

/* Text */
.module.text{
  text-align: center;
  .text--inner--text{
    text-align: left;
  }
  .text--inner--lists{
    margin-top: 20px;
    text-align: left;
    a{
      border-bottom: 1px solid $text-color;
      -webkit-transition: color .2s, border-bottom-color .2s;
      -moz-transition: color .2s, border-bottom-color .2s;
      -ms-transition: color .2s, border-bottom-color .2s;
      -o-transition: color .2s, border-bottom-color .2s;
      transition: color .2s, border-bottom-color .2s;
      &:hover{
        border-bottom-color: #fff;
      }
    }
    .text--inner--lists--item {
      &.text--inner--lists--right {
        margin-top: 50px;
      }
    }
  }
  @media (min-width: $screen-size-sm){
    padding-left: 0;
    padding-right: 0;
    .text--inner--lists {
      .text--inner--lists--item {
        float: left;
        width: 50%;
        ul {
          li {
            margin-bottom: 10px;
          }
        }
        &.text--inner--lists--left {
          padding-right: 15px;
        }
        &.text--inner--lists--right {
          margin-top: 0;
          padding-left: 15px;
        }
      }
    }
  }
}
.module.text.white + .module.text.white,
.module.text.gray + .module.text.gray,
.module.text.blue + .module.text.blue,
.module.text.orange + .module.text.orange{
  padding-top: 0;
}

/* Teaser Row */
.module.teaser-row{
  .teaser-row--inner{
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    @media (min-width: $screen-size-sm){
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }
  }
  .teaser{
    position: relative;
    @media (min-width: $screen-size-sm){
      -webkit-flex:1;
      -ms-flex:1;
      flex:1;
    }
    .teaser--img{
      position: relative;
      width: 100%;
      z-index: 1;
    }
    .teaser--content{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      &.teaser--content--overlay{
        background-color: $overlay-background-color;
      }
      color: #fff;
      text-align: center;
      .teaser--content--inner{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 20px;
        padding-bottom: 35px;
        .teaser--content--logo{
          img{
            display: inline;
            width: auto;
            max-height: 70px;
            max-width: 90%;
          }
        }
        .teaser--content--text{
          margin: 20px auto 20px;
          max-width: 90%;
        }
        .teaser--content--cta{
          position: absolute;
          bottom: 0;
          width: 100%;
          text-align: center;
        }
      }
      @media (orientation: landscape) and (max-width: $screen-size-sm){
        .teaser--content--inner {
          .teaser--content--text {
            max-width: 50%;
            font-size: 22px;
            line-height: 29px;
          }
        }
      }
      @media (min-width: $screen-size-xs){
        .teaser--content--inner{
          bottom: 30px;
          padding-bottom: 50px;
          .teaser--content--text{
            max-width: 50%;
            font-size: 22px;
            line-height: 29px;
          }
        }
        .teaser--content--cta{
          a{
            font-size: 22px;
            line-height: 29px;
          }
        }
      }
      @media (min-width: $screen-size-sm){
        .teaser--content--inner{
          .teaser--content--logo {
            img {
              max-width: 75%;
            }
          }
          .teaser--content--text {
            max-width: 75%;
            font-size: 18px;
            line-height: 24px;
          }
        }
        .teaser--content--cta{
          a{
            font-size: 18px;
            line-height: 24px;
            &:after{
              top: -1px;
            }
          }
        }
      }
      @media (min-width: $screen-size-sm) and (orientation: portrait) and (max-width: $screen-size-lg){
        .teaser--content--inner{
          .teaser--content--logo {
            img {
              max-width: 50%;
            }
          }
        }
        .teaser--content--cta{
          bottom: 5px;
        }
      }
      @media (orientation: landscape) and (max-width: $screen-size-md){
        .teaser--content--inner {
          .teaser--content--logo {
            img {
              max-width: 50%;
            }
          }
          .teaser--content--text {
            max-width: 75%;
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
      @media (min-width: $screen-size-xl){
        .teaser--content--inner {
          padding-bottom: 30px;
          .teaser--content--text {
            max-width: 70%;
            font-size: 22px;
            line-height: 32px;
          }
        }
      }
    }
    &.teaser--video{
      img{
        -webkit-transition: opacity .4s ease-in;
        -moz-transition: opacity .4s ease-in;
        -ms-transition: opacity .4s ease-in;
        -o-transition: opacity .4s ease-in;
        transition: opacity .4s ease-in;
      }
      video{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .teaser--play-overlay{
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        z-index: 4;
        -webkit-transition: opacity .4s ease-in;
        -o-transition: opacity .4s ease-in;
        transition: opacity .4s ease-in;
        opacity: 1;
        svg{
          width: 75px;
          color: hsla(0,0%,100%,.3);
          cursor: pointer;
          -webkit-transition: color .2s;
          -o-transition: color .2s;
          transition: color .2s;
        }
      }
      &:hover{
        .teaser--play-overlay{
          svg {
            color: rgba(255,255,255,1);
          }
        }
      }
      &.playing{
        img{
          opacity: 0;
        }
        .teaser--play-overlay{
          opacity: 0;
        }
        .teaser--content{
          opacity: 0;
        }
      }
    }
  }
  .teaser:nth-of-type(1){
    .reveal-sequence{
      -webkit-transition-delay: .1s;
      -moz-transition-delay: .1s;
      -ms-transition-delay: .1s;
      -o-transition-delay: .1s;
      transition-delay: .1s;
    }
  }
  .teaser:nth-of-type(2){
    .reveal-sequence{
      webkit-transition-delay: .2s;
      -moz-transition-delay: .2s;
      -ms-transition-delay: .2s;
      -o-transition-delay: .2s;
      transition-delay: .2s;
    }
  }
  .teaser:nth-of-type(3){
    .reveal-sequence{
      -webkit-transition-delay: .3s;
      -moz-transition-delay: .3s;
      -ms-transition-delay: .3s;
      -o-transition-delay: .3s;
      transition-delay: .3s;
    }
  }
  &.text-below-image{
    .teaser {
      display: flex;
      flex-direction: column;
      &:after{
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background-color: $brand-dark;
      }
      .teaser--content{
        position: relative;
        padding: 30px;
        height: auto;
        color: inherit;
        flex-grow: 1;
        display: flex;
        .teaser--content--inner{
          position: relative;
          .teaser--content--text{
            max-width: none;
          }
        }
        &:last-child{
          .teaser--content {
            border: 0;
          }
        }
      }
    }
  }
  &.fullscreen{
    padding: 0;
    .teaser-row--inner {

    }
  }
}

/* Text-Image */
.module.text-image{
  text-align: center;
  .text-image--inner{
    display: flex;
    flex-direction: column;
    .text-image--text{
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      justify-content: center;
      -webkit-transition-delay: .1s;
      -moz-transition-delay: .1s;
      -ms-transition-delay: .1s;
      -o-transition-delay: .1s;
      transition-delay: .1s;
      img{
        display: block;
        max-width: 50%;
        margin: 0 auto 20px;
      }
    }
    .text-image--image{
      margin-top: 30px;
      -webkit-transition-delay: .3s;
      -moz-transition-delay: .3s;
      -ms-transition-delay: .3s;
      -o-transition-delay: .3s;
      transition-delay: .3s;
    }
  }
  @media (min-width: $screen-size-sm) {
    text-align: left;
    .text-image--inner {
      flex-direction: row;
      .text-image--text{
        img{
          margin: 0 0 20px;
        }
        -webkit-transition-delay: .4s;
        -moz-transition-delay: .4s;
        -ms-transition-delay: .4s;
        -o-transition-delay: .4s;
        transition-delay: .4s;
      }
      .text-image--element {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
      }
      .text-image--image {
        margin-top: 0;
        padding-left: 40px;
        -webkit-transition-delay: .2s;
        -moz-transition-delay: .2s;
        -ms-transition-delay: .2s;
        -o-transition-delay: .2s;
        transition-delay: .2s;
      }
    }
    &.left{
      .text-image--image {
        padding-left: 0;
        padding-right: 40px;
      }
      .text-image--inner {
        flex-direction: row-reverse;
      }
    }
  }
  &.fullscreen{
    padding: 0;
    .text-image--inner {
      .text-image--text{
        padding: 30px 15px 15px;
        @media (min-width: $screen-size-sm) {
          padding: 50px;
          .text-image--text--inner{
            width: 80%;
            margin: auto;
          }
        }
      }
    }
  }
}

/* Quote Images */
.module.quote-images{
  background-color: $brand-gray;
  overflow: hidden;
  img{
    min-width: 100%;
  }
  .quote-images--content{
    text-align: center;
    padding: 20px;
    color: #fff;
  }
  @media (min-width: $screen-size-sm) {
    img{
      min-height: 100vh;
      max-width: none;
      width: auto;
    }
    .quote-images--content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 80px 20px;
      z-index: 2;
      text-align: left;
      color: $text-color;
      background: transparent !important;
      .quote-images--content--inner {
        position: relative;
        margin: auto;
        width: 100%;
        height: 100%;
        .quote-images--content--textbox {
          position: absolute;
          left: 0;
          top: 0;
          max-width: 570px;
          &.right {
            left: auto;
            right: 0;
          }
          &.bottom {
            top: auto;
            bottom: 0;
          }
        }
      }
    }
  }
  @media (min-width: $screen-size-lg) {
    .quote-images--content {
      padding: 180px 20px;
    }
  }
}
.module + .module.quote-images {
  .quote-images--slide{
    img {
      min-height: 0;
      min-width: 0;
      width: 100%;
      height: auto;
    }
    .quote-images--content {
      padding: 40px 20px;
    }
    @media (min-width: $screen-size-lg) {
      .quote-images--content {
        padding: 180px 20px;
      }
    }
  }
}

/* Icon Row */
.module.icon-row{
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  .icon-row--icon{
    border-right: 1px solid $text-color;
    text-align: center;
    width: 50%;
    margin: 20px 0;
    .icon-row--icon--icon-container{
      height: 60px;
      margin: auto;
      padding: 0 10px;
      img{
        height: 100%;
      }
    }
    .icon-row--icon--number,
    .icon-row--icon--headline,
    .icon-row--icon--subline{
      margin-top: 10px;
    }
    .icon-row--icon--headline{
      font-weight: 500;
    }
    &:nth-child(even){
      border-right: 0;
    }
  }
  @media (min-width: $screen-size-md+1){
    padding: 20px 0;
    .icon-row--icon {
      margin: 0;
      padding: 20px;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      .icon-row--icon--icon-container{
        height: 100px;
      }
      &:nth-child(even){
        border-right: 1px solid $text-color;
      }
      &:last-child{
        border: 0;
      }
    }
  }
  .reveal-sequence:nth-of-type(1){
     -webkit-transition-delay: .1s;
     -moz-transition-delay: .1s;
     -ms-transition-delay: .1s;
     -o-transition-delay: .1s;
     transition-delay: .1s;
   }
  .reveal-sequence:nth-of-type(2){
    -webkit-transition-delay: .2s;
    -moz-transition-delay: .2s;
    -ms-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
  }
  .reveal-sequence:nth-of-type(3){
    -webkit-transition-delay: .3s;
    -moz-transition-delay: .3s;
    -ms-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
  }
  .reveal-sequence:nth-of-type(4){
    -webkit-transition-delay: .4s;
    -moz-transition-delay: .4s;
    -ms-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
  }
  .reveal-sequence:nth-of-type(5){
    -webkit-transition-delay: .5s;
    -moz-transition-delay: .5s;
    -ms-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s;
  }
  .reveal-sequence:nth-of-type(6){
    -webkit-transition-delay: .6s;
    -moz-transition-delay: .6s;
    -ms-transition-delay: .6s;
    -o-transition-delay: .6s;
    transition-delay: .6s;
  }
  .reveal-sequence:nth-of-type(7){
    -webkit-transition-delay: .7s;
    -moz-transition-delay: .7s;
    -ms-transition-delay: .7s;
    -o-transition-delay: .7s;
    transition-delay: .7s;
  }
}

/* Large Icons */
.module.large-icons{
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  background-color: $brand-dark;
  color: $brand-gray;
  .large-icons--icons {
    margin: 30px auto 0;
    text-align: center;
    .large-icons--icon{
      display: inline-block;
      position: relative;
      height: 90px;
      width: 100%;
      img{
        width: auto;
        height: 100%;
      }
      @media (min-width: $screen-size-xs){
        margin: 0 40px;
        height: 140px;
        width: auto;
        &:first-child{
          margin-left: 0;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
    a{
      &:hover{
        opacity: .7;
      }
    }
    @media (min-width: $screen-size-sm) {
      .large-icons--icon{
        -webkit-flex:1;
        -ms-flex:1;
        flex:1;
        vertical-align: middle;
      }
    }
  }
  h2{
    -webkit-transition-delay: .1s;
    -moz-transition-delay: .1s;
    -ms-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;
  }
  .reveal-sequence:nth-of-type(1){
    -webkit-transition-delay: .2s;
    -moz-transition-delay: .2s;
    -ms-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
  }
  .reveal-sequence:nth-of-type(2){
    -webkit-transition-delay: .3s;
    -moz-transition-delay: .3s;
    -ms-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
  }
  .reveal-sequence:nth-of-type(3){
    -webkit-transition-delay: .4s;
    -moz-transition-delay: .4s;
    -ms-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
  }
}

/* CTA Row */
.module.cta-row{
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  a.btn{
    display: inline-block;
    margin-top: 20px;
    position: relative;
    width: 100%;
    &.link{
      padding-right: 5px;
      &:after{
        content: ">";
      }
    }
  }
  @media (min-width: $screen-size-sm) {
    a.btn{
      margin-top: 0;
      margin-left: 10px;
      width: auto;
    }
  }
}

/* Employee Contact */
.module.employee-contact{
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  .employee-contact--image{
    padding: 0 70px;
  }
  .employee-contact--content{
    margin-top: 25px;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    .employee-contact--content--text{
      margin-bottom: 20px;
    }
    .employee-contact--content--ctas{
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      .employee-contact--content--ctas--cta {
        margin-top: 25px;
        &:first-child {
          margin-top: 0;
        }
      }
      img{
        width: 24px;
        height: 24px;
        vertical-align: middle;
      }
      .employee-contact--content--ctas--cta--mail{
        &.svg-icon-line{
          padding-left: 40px;
          .svg-icon {
            svg {
              width: 30px;
            }
          }
        }
      }
    }
  }
  @media (min-width: $screen-size-sm) {
    text-align: left;
    .employee-contact--image{
      padding: 0;
    }
    .employee-contact--content {
      margin-top: 0;
      padding-left: 30px;
      .employee-contact--content--ctas {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        .employee-contact--content--ctas--cta {
          margin-left: 20px;
          margin-top: 0;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

/* Contact */
.module.contact{
  color: $text-color;
  .contact--form{
  }
  .nf-field-container{
    margin-bottom: 25px;
  }
  @media (min-width: $screen-size-sm){
    .contact--form{
      padding-right: 25px;
    }
    .contact--address{
      padding-left: 25px;
    }
    .nf-field-container{
      margin-bottom: 50px;
    }
  }
  iframe{
    width: 100%;
    height: 400px;
  }
}

.module.logos{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .logo{
    width: 50%;
    padding: 30px;
    img{
      max-height: 100px;
      margin: auto;
      display: block;
    }
  }
  @media(min-width: $screen-size-xs-portrait){
    .logo{
      width: 33.3333%;
    }
  }
  @media(min-width: $screen-size-md){
    .logo{
      flex: 1;
    }
  }
}

.module.accordion{
  background: $brand-light-blue;
  h3{
    color: $brand-dark-blue;
    margin-bottom: 20px;
    @media (min-width: $screen-size-xs){
      margin-bottom: 30px;
    }
  }
  .accordion--entry{
    margin-top: -1px;
    .accordion--entry--header{
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      .accordion--entry--header--inner{
        a{
          padding: 15px 0;
          padding-right: 50px;
          display: block;
          color: #fff;
          position: relative;
          font-weight: 600;
          .accordion--icon{
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 0;
            svg{
              vertical-align: middle;
            }
            &.minus{
              display: none;
            }
            &.plus{
              display: block;
            }
          }
          &:hover{
            color: $brand-dark-blue;
            .accordion--icon{
              .accordion--icon--inner{
                border-color: $brand-dark-blue;
                .bar{
                  background-color: $brand-dark-blue;
                }
              }
            }
          }
          &:active,
          &:focus{
            color: #fff;
          }
        }
      }
    }
    .accordion--entry--body{
      background: $brand-gray;
      color: $text-color;
      display: none;
      padding: 10px 0;
      .accordion--entry--body--inner{
        padding: 15px 40px;
        @media(min-width: $screen-size-xl){
          padding: 15px 45px;
        }
        p:last-of-type{
          margin: 0;
        }
        .accordion--entry--body--image{
          @media (min-width: $screen-size-xs) {
            max-width: 740px;
          }
          margin-bottom: 20px;
        }
      }
    }
    &.open {
      .accordion--entry--header{
        .accordion--entry--header--inner {
          a {
            .accordion--icon {
              &.minus{
                display: block;
              }
              &.plus{
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .content{
    padding: 0 40px;
  }
  @media(min-width: $screen-size-xl){
    .content{
      padding: 0;
    }
  }
}
.job-single,
.module.text,
.module.accordion{
  ul {
    margin-bottom: 20px;
    li {
      padding-left: 29px;
      margin-bottom: 10px;
      &:before {
        content: url("../img/icon-hand.svg");
        position: absolute;
        width: 15px;
        margin-left: -25px;
        line-height: 32px;
      }
    }
  }
}

.icon-list{
  .icon-list--elements{
  }
  .icon-list--element{
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid $text-color;
    .icon-list--element--inner{
      display: flex;
      align-items: center;
      margin: 0 -20px;
      padding: 25px 0;
      flex-direction: column;
      .icon-list--element--img,
      .icon-list--element--text{
        padding: 0 20px;
      }
      .icon-list--element--img{
        width: 30%;
        margin-bottom: 20px;
      }
      .icon-list--element--text{
        width: 100%;
      }
    }
    @media(min-width: $screen-size-xs-portrait){
      text-align: left;
      .icon-list--element--inner {
        flex-direction: row;
        .icon-list--element--img {
          width: 10%;
          margin-bottom: 0;
        }
      }
    }
  }
}

.popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  .overlay,
  .popup--text{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .popup--text{
    max-height: 100%;
    overflow-y: scroll;
    padding: 20px;
    @media (min-width: $screen-size-xs){
      padding: 50px 0;
    }
    @media (min-width: $screen-size-sm){
      padding: 150px 0;
    }
    .popup--text--box{
      position: relative;
      left: 0;
      position: relative;
      max-width: 900px;
      padding: 25px;
      @media (min-width: $screen-size-sm){
        padding: 50px;
      }
      p:last-child{
        margin-bottom: 0;
      }
      .close{
        position: absolute;
        top: 5px;
        right: 25px;
        z-index: 1;
        @media (min-width: $screen-size-sm){
          top: 15px;
          right: 15px;
        }
      }
      .popup--text-wrapper{
        position: relative;
        z-index: 2;
      }
    }
    .background{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/* Animations */
.reveal,
.reveal-sequence{
  opacity: 0;
  -webkit-transform: translateY(20%);
  -moz-transform: translateY(20%);
  -ms-transform: translateY(20%);
  -o-transform: translateY(20%);
  transform: translateY(20%);

  /*-webkit-transform: translateY(0) scale(1);
  -moz-transform: translateY(0) scale(1);
  -ms-transform: translateY(0) scale(1);
  -o-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);*/

  -webkit-transition: all ease .8s;
  -moz-transition: all ease .8s;
  -ms-transition: all ease .8s;
  -o-transition: all ease .8s;
  transition: all ease .8s;
}
.scroll-active{
  .reveal,
  .reveal-sequence{
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}