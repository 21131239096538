.svg-icon-line{
  display: inline;
  position: relative;
  .svg-icon{
    position: absolute;
    top: 50%;
    left: 5px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    svg{
      width: 25px;
      vertical-align: middle;
    }
    &.black{
      color: $text-color;
    }
  }
}
.clearfix{
  clear: both;
}