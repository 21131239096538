body{
  font-family: 'Roboto', sans-serif;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 23px;
}
.pure-g [class*=pure-u]{
  font-family: 'Roboto', sans-serif;
}
header{
  font-size: 15px;
}
footer{
  font-size: 15px;
  line-height: 19px;
}
blockquote{
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 10px;
}
h1,h2,h3,h4,h5,
.h1,.h2,.h3,.h4,.h5{
  font-weight: 500;
}
h1,
.h1{
  font-size: 38px;
  line-height: 48px;
  margin-bottom: 10px;
}
h2,
.h2{
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 20px;
}
h3,
.h3{
  font-size: 18px;
  line-height: 22px;
}
h4,
.h4{
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 5px;
  font-weight: 400;
}
@media (min-width: $screen-size-xs){
  body{
    font-size: 22px;
    line-height: 32px;
  }
  header{
    font-size: 16px;
  }
  footer{
    font-size: 16px;
  }
  blockquote{
    font-size: 48px;
    line-height: 56px;
  }
  b,strong{
    font-weight: 500;
  }
  h1,
  .h1{
    font-size: 68px;
    line-height: 72px;
  }
  h2,
  .h2{
    font-size: 48px;
    line-height: 56px;
  }
  h3,
  .h3{
    font-size: 30px;
    line-height: 38px;
  }
  h4,
  .h4{
    font-size: 22px;
    line-height: 32px;
  }
}
@media (min-width: $screen-size-md){
  header{
    font-size: 16px;
  }
  footer{
    font-size: 16px;
  }
  blockquote{
    font-size: 48px;
    line-height: 56px;
  }
  b,strong{
    font-weight: 500;
  }
  h1,
  .h1{
    font-size: 68px;
    line-height: 72px;
  }
  h2,
  .h2{
    font-size: 48px;
    line-height: 56px;
  }
  h3,
  .h3{
    font-size: 30px;
    line-height: 38px;
  }
}